import * as React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Box,
} from "@mui/material";




const Button = styled(MuiButton)(({ theme }) => ({
	color: theme.palette.secondary.contrastText,
  margin: "0px 10px",
  whiteSpace: "nowrap",
	fontSize: "1.0rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "1.4rem"
	}
}));

const AppBarComponent = () => (
  <Box  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} mt={10} pb={5}>
    
               

    <Button
      color="inherit"
      href={"https://goo.gl/maps/DKG2QCdSonwnRhav9"}
      target="_blank"
    >
      Hitta hit
    </Button>
    <Button
      color="inherit"
      target="_blank"
      onClick={() => {
        var element = document.getElementById('contactUs')
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }}  
    >
      Kontakta oss
    </Button>
    <Button
      color="inherit"
      href="https://campkajutan.se/"
      target="_blank"
    >
      Camp Kajutan
    </Button>
  </Box>
);

export default AppBarComponent;

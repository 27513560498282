import React from "react";
import styled from "styled-components/macro";

import {
  Container,
  Grid,
  Typography as MuiTypography,
  List,
  ListItem,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";
import Divider from "../../components/Divider";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
    padding-top: 4.0rem;
    padding-bottom: 4.0rem;
	position: relative;
	text-align: center;
	overflow: hidden;
`;
const ListItemText = styled(MuiListItemText)`
    & span{
        font-size: 1.0rem;
        font-weight: 600;
    }
`;
const Price = styled(Typography)`
  font-weight: 900;
  font-size: 1.1rem;
  line-height:100%;
  color: ${(props) => props.theme.palette.gold};
`
const Title = styled(Typography)`
  font-weight: 900;
  font-size: 1.5rem;
  line-height:100%;
  text-align: left;
  color: ${(props) => props.theme.palette.gold};
`


const menu = {
    "Mackor":[
        {
            name: "Räkor",
            description: "",
            price: 169
        },
        {
            name: "Räkor Liten",
            description: "",
            price: 119
        },
        {
            name: "Kyckling",
            description: "",
            price: 119
        },
        {
            name: "Köttbulle",
            description: "",
            price: 95
        },
        {
            name: "Ansjovis & Ägg",
            description: "",
            price: 75
        },
        {
            name: "Salami & Brie",
            description: "",
            price: 75
        },
        
        {
            name: "Vegetarisk",
            description: "",
            price: 105
        },
        {
            name: "Skinka & Ost",
            description: "",
            price: 50
        },
    ],
    'Dryck':[
        {
            name: "Kaffe, Te",
            description: "",
            price: 29
        }, 
        {
            name: "Läsk",
            description: "",
            price: 24
        },
        {
            name: "Festis",
            description: "",
            price: 15
        },
        {
            name: "Öl",
            description: "",
            price: 85
        },
        {
            name: "Prosecco",
            description: "",
            price: 85
        },
        {
            name: "Cider",
            description: "",
            price: 85
        },
        {
            name: "Vin",
            description: "",
            price: 85
        },
        {
            name: "Irish Coffe",
            description: "",
            price: 85
        },
    ],
    "Mat":[
        {
            name: "Bakad potatis",
            description: "Kyckling eller ishavsröra",
            price: 105
        },
        {
            name: "Sallad",
            description: "Kyckling, Räk eller Vegitarisk",
            price: 119
        },
        {
            name: "Taco tallrik",
            description: "Finns även med quorn",
            price: 129
        },
        {
            name: "Nacho + dipp",
            description: "Cheddar, Salsa & Creme fraiche ",
            price: 55
        },
        {
            name: "Plättar (3st)",
            description: "Med sylt & grädde",
            price: 55
        },

    ],
    "Pizza":[
        {
            name: "Vesuvio",
            description: "Skinka, Ost",
            price: 120
        },
        {
            name: "Hawaii",
            description: "Skinka, Annanas, Ost",
            price: 120
        },
        {
            name: "Capricciosa",
            description: "Skinka, Champinjoner, Ost",
            price: 120
        },
        {
            name: "Vegitarisk",
            description: "Pesto, Mozzarella, Tomat, Ost, Paprika",
            price: 125
        },
        {
            name: "Kebab",
            description: "Kebab, Lök, Tomat, Feferoni, Ost, Paprika",
            price: 125
        },
        {
            name: "Salami",
            description: "Mozzarella, Pesto, Tomat, Paprika",
            price: 125
        },
        {
            name: "Kajutan Bianco",
            description: "Creme fraiche, Norrlandsost, Räkor, Tångkaviar, Lök, Citron, Dill",
            price: 169
        },
    ]
}


function Menu() {
	return (
		<Wrapper>
		<Container id="menu">
			<Grid container alignItems="center" justifyContent="center" spacing={4}>
				<Grid item xs={12} sm={11} md={8} lg={8}>
				
					
						<Divider index={7} >
							{"Meny"}
						</Divider>
					
						<Grid container justifyContent="center" spacing={10} sx={{mt: 10}}>
                            
								{
                                    Object.keys(menu).map((title, index) => (
                                        <Grid item xs={12} sm={6} md={6} lg={6} key={"meny"+index}>
                                                <Title sx={{ml:4}}>{title}</Title>
                                                <List>
                                                    {
                                                        menu[title].map((item, index2) => (
                                                            <ListItem key={"menysub"+index+"-"+index2}>
                                                                <ListItemText primary={item.name}  secondary={item.description}/>
                                                                <Price>
                                                                {item.price} 
                                                                </Price> 
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                        </Grid>
                                    ))
                                }

							
						</Grid>
				</Grid>
			</Grid>
		</Container>
		</Wrapper>
	);
}

export default Menu;

import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Container,
  Grid,
  Typography as MuiTypography,
  List,
  ListItem,
  ListItemText as MuiListItemText,
  ListItemAvatar,
  Divider as MuiDivider,
  Collapse,
  IconButton,
  Link as MuiLink
} from "@mui/material";
import { spacing } from "@mui/system";
import Divider from "../../components/Divider";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ListItemText = styled(MuiListItemText)`
    & span{
        font-size: 1.0rem;
        font-weight: 600;
    }
`;
const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
	background: ${(props) => props.theme.palette.background.paper};
	padding-top: 4.0rem;
	padding-bottom: 4.0rem;
	position: relative;
	text-align: center;
	overflow: hidden;
`;

const Link = styled(MuiLink)`
    text-decoration: underline;
    &:hover{
        text-decoration: none;

    }
`

/*
const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;*/

const DateText = styled(Typography)`
  font-weight: 900;
  font-size: 1.4rem;
  line-height:100%;
  margin-right:10px;
`

const MonthText = styled(Typography)`
  font-weight: 400;
  font-size: 0.9rem;
  margin-top:5px;
  line-height:100%;
  margin-right:10px;
`
const maxEvents = 5;
const events = [
    {
        date: "2022-06-02 19:00 - 22.00",
        title: "Dans till Ola & Jag",
        description: ""
    },
    {
        date: "2022-06-06 12:30 - 14.30",
        title: "Norrlands Motorhistoriker Gästar",
        link: "https://www.facebook.com/events/684864159154969",
        description: ""
    },
    {
        date: "2022-06-09 19:00 - 22.00",
        title: "Dans till Rytmix",
        description: ""
    },
    {
        date: "2022-06-15 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        link:"https://fb.me/e/1JqnH9u8O",
        description: ""
    },
    {
        date: "2022-06-16 19:00 - 22.00",
        title: "Dans till Jive",
        description: ""
    },
    {
        date: "2022-06-19 13:00 - 15.00",
        title: "Bälgadraget spelar & Sjunger",
        description: ""
    },
    {
        date: "2022-06-22 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        link: "https://fb.me/e/1Zk49EQsz",
        description: ""
    },
    {
        date: "2022-06-23 19:00 - 22.00",
        title: "Dans till Kvarnby",
        description: ""
    },
    {
        date: "2022-06-24 13:00 - 15.00",
        title: "Birger Sundströms Quartett underhåller",
        description: ""
    },
    {
        date: "2022-06-28 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-06-29 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        link: "https://fb.me/e/3fizkGF4Q",
        description: ""
    },

    {
        date: "2022-06-30 19:00 - 22.00",
        title: "Dans till Mickes",
        description: ""
    },
    {
        date: "2022-07-05 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-07-06 19:00 - 22.00",
        title: "Dans till Avant",
        description: ""
    },
    {
        date: "2022-07-07 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        description: ""
    },
    {
        date: "2022-07-08 18:00 - 21.00",
        title: "Walle från Gävle spelar",
        description: ""
    },
    {
        date: "2022-07-09",
        title: "Norrlands Motorhistoriker Gästar",
        description: ""
    },
    {
        date: "2022-07-09 12:00",
        title: "Galagänget uppträder på scenen",
        description: ""
    },
    {
        date: "2022-07-12 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-07-19 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-07-21 19:00 - 22.00",
        title: "Dans till Jive",
        description: ""
    },
    {
        date: "2022-07-23",
        title: "Norrlands Motorhistoriker Gästar",
        description: ""
    },
    {
        date: "2022-07-26 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-08-02 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-08-03 19:00 - 22.00",
        title: "Dans till Avant",
        description: ""
    },
    {
        date: "2022-08-04 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        description: ""
    },
    {
        date: "2022-08-07 11:00 - 14.00",
        title: "Buskspel",
        description: ""
    },
    {
        date: "2022-08-07 14:00 - 15.00",
        title: "Bälgadraget spelar",
        description: ""
    },
    {
        date: "2022-08-09 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },
    {
        date: "2022-08-11 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        description: ""
    },
    {
        date: "2022-08-11 19:00 - 22.00",
        title: "Dans till Carisma",
        description: "",
        canceled: true
    },
    
    {
        date: "2022-08-16 17:00 - 20.00",
        title: "Kvällsloppis",
        description: ""
    },

    {
        date: "2022-08-18 18:00 - 20.00",
        title: "Joel Núñez Spelar",
        description: ""
    },
    {
        date: "2022-08-18 19:00 - 22.00",
        title: "Dans till Mickes",
        description: "",
        canceled: true
    },
    
    {
        date: "2022-08-20 18:00 - 24.00",
        title: "Räkfrossa (måste bokas)",
        description: ""
    }
        
    ,{
        date: "2022-08-23",
        title: "Politikerveckan - Miljöpartiet",
        description: "",
    }
    ,{
        date: "2022-08-24",
        title: "Politikerveckan - Centerpartiet",
        description: "",
    }
    ,{
        date: "2022-08-25",
        title: "Politikerveckan - Socialdemokraterna",
        description: "",
    }
    ,{
        date: "2022-08-27",
        title: "Politikerveckan - Kristdemokraterna",
        description: "",
    }
    ,{
        date: "2022-08-28",
        title: "Politikerveckan - Sverigedemokraterna",
        description: "",
    }
]

var eventList = events.map(items => {
    const _thisDate = items.date.split(" ");
    var _thisRealDate  = new Date(_thisDate[0]);
   

    if(_thisDate.length > 1){
        const _timeSplit = _thisDate[1].split(':');
        if(_timeSplit.length > 1){
            _thisRealDate.setHours(_timeSplit[0]);
            _thisRealDate.setMinutes(_timeSplit[1]);
        }
    }

    var _todayDate = _thisRealDate;

    _todayDate.setHours(23);
    _todayDate.setMinutes(0);

    
    return {
        date: _thisRealDate,
        todayDate: _todayDate,
        dateText: items.date,
        title: items.title,
        link: items.link,
        description: items.description,
        canceled: items.canceled
    };
}).sort((a, b) => new Date(a.date) - new Date(b.date));

console.log(eventList);
const today = new Date();
const months = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "Noveber",
    "December"
];

const days = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag"
];
const getThisDay = (date) => {
    const day = date.getDate();
    return (day < 10 ? "0": "")+day;
}
const getThisMonth = (date) => {
    return months[date.getMonth()];
}
const getThisDate = (dateText) => {

    const _thisDate2 = dateText.split(" ");
    var date  = new Date(_thisDate2[0]);
    if(_thisDate2.length > 1){
        const _timeSplit = _thisDate2[1].split(':');
        if(_timeSplit.length > 1){
            date.setHours(_timeSplit[0]);
            date.setMinutes(_timeSplit[1]);
        }
    }

    if(_thisDate2.length === 1){
        return days[date.getDay()];
    }else if(_thisDate2.length === 2){
        return days[date.getDay()] + " klockan " + _thisDate2[1];
    }else if(_thisDate2.length === 4){
        return days[date.getDay()] + " från klockan "+_thisDate2[1] + " till "+_thisDate2[3];
    }

    
}
const RenderItem = ({item}) => {
    return (
    <React.Fragment>
        <ListItem>
            <ListItemAvatar>
                <DateText>
                    {getThisDay(item.date)}
                </DateText>
                <MonthText>
                    {getThisMonth(item.date)}
                </MonthText>
            </ListItemAvatar>
            <ListItemText primary={
                <React.Fragment>
                    <span style={{textDecoration: item.canceled ? 'line-through' : ''}}>
                        {item.link && <Link href={item.link} target="_blank">{item.title}</Link>}
                        {!item.link && item.title }
                    </span>
                    {item.canceled && <span style={{marginLeft: '5px', color: 'red'}}>Inställd</span> }
                </React.Fragment>
            } secondary={getThisDate(item.dateText)}/>
        </ListItem>
        <MuiDivider variant="inset" component="li" />
    </React.Fragment>);
}
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
function Event() {
    const [expanded, setExpanded] = React.useState(false);
   
	return (
		<Wrapper>
		<Container id="event">
			<Grid container alignItems="center" justifyContent="center" spacing={4}>
				<Grid item xs={12} sm={9} md={8} lg={8}>
				
					
						<Divider index={4} >
							{"Aktiviteter"}
						</Divider>
					
						<Grid container justifyContent="center" spacing={4}>
							<Grid item xs={12} lg={10}>
								<Box mt={10}>
                                    <List>
                                        {eventList.filter(item => item.todayDate >= today).filter((item, index) => index < maxEvents).map((item, index) => (
                                            <RenderItem item={item} key={"todo-"+index} /> 
                                        ))}
                                        
                                        {eventList.filter(item => item.todayDate >= today).length > maxEvents && (
                                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                {eventList.filter(item => item.date >= today).filter((item, index) => index >= maxEvents).map((item, index) => (
                                                    <RenderItem item={item} key={"todof-"+index}/> 
                                                ))}
                                            </Collapse>
                                        )}
                                    </List>
                                    {!expanded && (
                                        <ExpandMore
                                            expand={expanded}
                                            onClick={() => setExpanded(true)}
                                            aria-expanded={expanded}
                                            aria-label="Visa fler event"
                                      >
                                        <ExpandMoreIcon />
                                      </ExpandMore>
                                    )}
                                    {expanded && (
                                        <ExpandMore
                                            expand={expanded}
                                            onClick={() => setExpanded(false)}
                                            aria-expanded={expanded}
                                            aria-label="Visa mindre"
                                      >
                                       <ExpandMoreIcon />
                                      </ExpandMore>
                                    )}
								</Box>

								
							</Grid>
						</Grid>
				</Grid>
			</Grid>
		</Container>
		</Wrapper>
	);
}

export default Event;

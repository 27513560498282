import React from "react";
import styled from "styled-components/macro";
import { useTheme } from '@mui/styles';


import {
	Box,
	Typography
} from "@mui/material";


import { ReactComponent as Splitter01 } from "../../vendor/splitter-01.svg";
import { ReactComponent as Splitter02 } from "../../vendor/splitter-02.svg";
import { ReactComponent as Splitter03 } from "../../vendor/splitter-03.svg";
import { ReactComponent as Splitter04 } from "../../vendor/splitter-04.svg";
import { ReactComponent as Splitter05 } from "../../vendor/splitter-05.svg";
import { ReactComponent as Splitter06 } from "../../vendor/splitter-06.svg";
import { ReactComponent as Splitter07 } from "../../vendor/splitter-07.svg";

const Title = styled(Typography)`
	display: block;
`;

const DividerComponent = ({index, color,fill, children}) => {
	const theme = useTheme();

	var SplitterSelect = Splitter01;

	if(index === 1){
		SplitterSelect = Splitter01
	}else if(index === 2){
		SplitterSelect = Splitter02
	}else if(index === 3){
		SplitterSelect = Splitter03
	}else if(index === 4){
		SplitterSelect = Splitter04
	}else if(index === 5){
		SplitterSelect = Splitter05
	}else if(index === 6){
		SplitterSelect = Splitter06
	}else if(index === 7){
		SplitterSelect = Splitter07
	}

	
	
	
	return (
	<React.Fragment>
		<Box sx={{
			display: 'flex',
			justifyContent: 'center'
		}} >
			
			<Title variant="h2" gutterBottom style={{color: (color ? color : 'inherit')}}>
				{children}
			</Title>
		</Box>
		<Box sx={{
			display: 'flex',
			justifyContent: 'center'
		}} mt={5} mb={10}>
			<SplitterSelect style={{fill: (fill ? fill : theme.header.color), color: (fill ? fill : theme.header.color)}}/>
		</Box>
	</React.Fragment>
	);
};

export default DividerComponent;

import * as React from "react";
import styled from "styled-components/macro";

import {
	Box,
	Grid,
	Button,
	Container,
	Typography
} from "@mui/material";
import Divider from "../../components/Divider";

import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Wrapper = styled.div`
	background: left top no-repeat;
	background-color:  ${(props) => props.theme.footer.background};
	background-image: url(${"static/img/backgrounds/map.svg"});
	position: relative;
	text-align: center;
	overflow: hidden;
`;
const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  color: ${(props) => props.theme.footer.color};
  text-align: center;
`;
const Aside = styled("aside")`
  color: #8181af;
  font-weight: 700;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  bottom: 130px;
  right: -80px;
  text-align: right;
  font-size:21px;
  opacity:0.15;
`;



const Footer = ({children}) => (
		<Wrapper>
			<Container>
				<Grid container alignItems="center" justifyContent="center" spacing={4}>
						<Grid item xs={12} sm={9} md={8} lg={8} >
							<Box m={10} mt={20}>
								<Divider index={7} color={"#fff"}>
									{"Hitta hit"}
								</Divider>

								<Grid container justifyContent="center">
									<Grid item xs={12} lg={10}>
										<Box mt={10}>
											<Subtitle >
												{"Vi finns ca en mil öst om Skellefteå på södra sidan av Skellefteälven. Följ Bockholmsvägen tills du kommer till Stackgrönnan och där svänger du av till Slipvägen och sedan direkt till höger på Räkvägen."}
											</Subtitle>
										</Box>
									</Grid>
								</Grid>
								
								<Box mt={20}  sx={{
									display: 'flex',
									justifyContent: 'center',
								}}>
									<Button
										href="https://goo.gl/maps/DKG2QCdSonwnRhav9"
										variant="contained"
										color="button"
										size="large"
										style={{ borderRadius: 10, padding: "22px 44px", fontSize: "1.1rem", fontWeight: 600  }}>
											Slipvägen 23, Skellefteå
											<ArrowForward />
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Aside>
						64.7093° N, 21.1301° W
					</Aside>
			</Container>
		</Wrapper>
);

export default Footer;

import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Container,
  Grid,
  Typography as MuiTypography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";
import Divider from "../../components/Divider";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
	background: ${(props) => props.theme.palette.background.paper};
	padding-top: 3.5rem;
	padding-bottom: 3.5rem;
	position: relative;
	text-align: center;
	overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;


function ContactUs() {
	return (
		<Wrapper>
		<Container id="contactUs">
			<Grid container alignItems="center" justifyContent="center" spacing={4}>
				<Grid item xs={12} sm={9} md={8} lg={8}>
					<Content>
						
					
						<Divider index={6} >
							{"Kontakta oss"}
						</Divider>
					
						<Grid container justifyContent="center" spacing={4}>
							<Grid item xs={12} lg={10}>
								<Subtitle color="textSecondary">
									{"Vill du komma i kontakt med oss? Ring oss gärna mellan 09.00 - 20.00."}
									<br />
									{"Men du kan även mejla oss."}
								</Subtitle>

								<Box mt={10}>
									<Typography variant="h4">Telefon</Typography>
									<Subtitle color="textSecondary">
										{"0910 - 863 40"}
									</Subtitle>
								</Box>

								<Box mt={10}>
									<Typography variant="h4">E-post</Typography>
									<Subtitle color="textSecondary">
										
										<Link target="_blank" rel="noreferrer" href="mailto:info@cafekajutan.se" color="gold" variant="link">
										{"info@cafekajutan.se"}
                                        </Link>
									</Subtitle>
								</Box>

							</Grid>
						</Grid>
					</Content>
				</Grid>
			</Grid>
		</Container>
		</Wrapper>
	);
}

export default ContactUs;

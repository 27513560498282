import React from "react";
import styled from "styled-components/macro";

import {
  Container,
  Grid,
  List,
  ListItem as ListItemMui,
  ListItemText
} from "@mui/material";
import Divider from "../../components/Divider";


const Wrapper = styled.div`
    background-color:  ${(props) => props.theme.subHeader.background};
	padding-top: 4.0rem;
	padding-bottom: 4.0rem;
    position: relative;
    text-align: center;
    overflow: hidden;
`;


const TypoDate = styled("span")`
    color:  ${(props) => props.theme.subHeader.dateText};
    font-size: ${(props) => props.theme.typography.h5.fontSize};
`
const TypoText = styled(ListItemText)`
  & > span{
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    color:  ${(props) => props.theme.subHeader.color};
  }
`
const ListItem = styled(ListItemMui)`
    padding-top:0px;
    padding-bottom:0px;


`;


const today = new Date();

const startDay = new Date('2024-05-25');
const endDay = new Date('2024-08-25');
const afterMidsummerDate = new Date('2024-06-21');
const closingWeek = new Date('2024-08-12');


const beforeMidsummer = "11.00 - 20:00";
const afterMidsummer = "11.00 - 21:00";
const onMidsummer = "11.00 - 15:00";
const afterClosingWeek = "11.00 - 20:00";

const daysInWeek = [
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
    'Söndag'
]

const isSameDate = (a, b) => Math.abs(a - b) < (1000 * 3600 * 24) && a.getDay() === b.getDay();

const getWeek = (_this) => {
    var date = new Date(_this.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
}


function Openings() {
	return (
		<Wrapper>
		<Container>
			<Grid container alignItems="center" justifyContent="center" spacing={4}>
			<Grid item xs={12} sm={9} md={8} lg={8}>
				
				
					<Divider index={1} color={"#FFF"} fill={"#494756"} >
						{"Öppettider"}
					</Divider>
				
					<Grid container justifyContent="center">
                        
						<Grid item  xs={10} sm={6} md={5} lg={4}>


                            {(getWeek(today) < getWeek(startDay) || getWeek(today) > getWeek(endDay)) && (
                                <List>
                                    {daysInWeek.map((item, index) => (
                                        <ListItem key={"one-"+index}>
                                            <TypoText primary={item}/>
                                            <TypoDate>{"Stängt"}</TypoDate>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                            
							{getWeek(today) === getWeek(startDay) && (
                                <List>
                                    {daysInWeek.map((item, index) => {
                                        console.log(today, startDay, today >= startDay);
                                        if(today >= startDay){
                                            console.log("open");
                                            return <ListItem key={"two-"+index}>
                                                <TypoText primary={item}/>
                                                <TypoDate>{beforeMidsummer}</TypoDate>
                                            </ListItem>
                                        }else{
                                            console.log("closed");
                                            return <ListItem key={"two-"+index}>
                                                <TypoText primary={item}/>
                                                {index <= 4 && <TypoDate>{"Stängt"}</TypoDate>}
                                                {index > 4 && <TypoDate>{beforeMidsummer}</TypoDate>}
                                            </ListItem>
                                        }
                                        
                                       
                                    })}
                                </List>
                            )}

                            {getWeek(today) > getWeek(startDay) && getWeek(today) < getWeek(afterMidsummerDate) && getWeek(today) <= getWeek(endDay) && (
                                <List>
                                    {daysInWeek.map((item, index) => (
                                        <ListItem key={"three-"+index}>
                                            <TypoText primary={item}/>
                                            <TypoDate>{beforeMidsummer}</TypoDate>
                                        </ListItem>
                                    ))}
                                </List>
                            )}



                            {(getWeek(today)) > getWeek(afterMidsummerDate) && getWeek(today) < getWeek(endDay) && getWeek(today) < getWeek(closingWeek) && (
                                <List>
                                    {daysInWeek.map((item, index) => (
                                        <ListItem key={"four-"+index}>
                                            <TypoText primary={item}/>
                                            <TypoDate>{afterMidsummer}</TypoDate>
                                        </ListItem>
                                    ))}
                                </List>
                            )}


                            {(getWeek(today)) == getWeek(afterMidsummerDate)  && (
                                <List>
                                    {daysInWeek.map((item, index) => {
                                        console.log(today, startDay, today >= startDay);
                                        
                                            console.log("closed");
                                            return <ListItem key={"two-"+index}>
                                                <TypoText primary={item}/>
                                                {index < 4 && <TypoDate>{beforeMidsummer}</TypoDate>}
                                                {index == 4 && <TypoDate>{onMidsummer}</TypoDate>}
                                                {index > 4 && <TypoDate>{afterMidsummer}</TypoDate>}
                                            </ListItem>
                                        
                                       
                                    })}
                                </List>
                            )}


                            {(getWeek(today)) >= getWeek(closingWeek) && getWeek(today) <= getWeek(endDay) && (
                                <List>
                                    {daysInWeek.map((item, index) => (
                                        <ListItem key={"four-"+index}>
                                            <TypoText primary={item}/>
                                            <TypoDate>{afterClosingWeek}</TypoDate>
                                        </ListItem>
                                    ))}
                                </List>
                            )}


                            
						</Grid>
					</Grid>

			</Grid>
			</Grid>
		</Container>
		</Wrapper>
	);
}

export default Openings;

import { THEMES } from "../constants";

const customBlue = {
	50: "",
	100: "",
	200: "",
	300: "#20232F",
	400: "#343444",
	500: "#403F54",
	600: "",
	700: "#333142",
	800: "",
	900: "",
};
const gold = "#AD9875";

const defaultVariant = {
	name: THEMES.DEFAULT,
	palette: {
		mode: "light",
		primary: {
			main: customBlue[700],
			contrastText: "#FFF",
		},
		secondary: {
			main: customBlue[500],
			contrastText: "#FFF",
		},
		background: {
			default: "#F7F9FC",
			paper: "#FFF",
		},
		button: {
			main: customBlue[300],
			contrastText: "#FFF",
		},
		gold: gold
	},
	header: {
		color: gold,
		background: customBlue[500],
		brand: {
			color: "#FFF"
		},

	},
	subHeader: {
		color: "#fff",
		background: customBlue[700],
		dateText: gold
	},
	footer: {
		color: "#fff",
		background: customBlue[400],
		button: customBlue[300]
	}
};


const variants = [
	defaultVariant
];

export default variants;

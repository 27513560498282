import React from "react";
import styled from "styled-components/macro";

import {
  Container,
  Grid,
  Typography as MuiTypography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";
import Divider from "../../components/Divider";
import Wrapper from "../../components/Wrapper";

const Typography = styled(MuiTypography)(spacing);


const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  text-align: left;
`;


const Stacke = styled("div")`
	display: block;
	position: relative;
	max-width: 750px;
	width: 100%;
	margin: 40px auto;
	
	&:before{
		content: '';
		display: block;
		position: relative;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		border-radius: 10px;
		max-width: 750px;
		height: 271px;
		background-image: url(${"static/img/backgrounds/stacke.jpg"});
	}
	&[alt]:after {
		content: attr(alt);
		text-align: right;
		width: 100%;
		display: block;
		font-size: 11px;
		margin-top: 2px;
	}

`

function History() {
	return (
		<Wrapper>
			<Container>
				<Grid container alignItems="center" justifyContent="center" spacing={4}>
					<Grid item xs={12} sm={9} md={8} lg={8}>
						<Divider index={5} >
							{"Café med historia"}
						</Divider>
					
						<Grid container justifyContent="center">
							<Grid item xs={12} lg={10}>
								<Subtitle color="textSecondary">
									{"Sedan 1920-talet så har det bedrivits fartygsbyggnation, båtvarv, rederi och timmerbogsering inom caféets område. Början 80-talet så lades verksamheten ned och lokalerna stuvades om till ett båtmuseum. I båtmuseumet så visas det upp både allmogebåtar från 1900 talets början samt en båtmotorsamling. Museumet finns fortfarande kvar och du kan läsa mer om det här "} 
									<Link target="_blank" rel="noreferrer" href="https://skellefteamuseum.se/pa-museet/stackgronnans-batmuseum/" color="gold" variant="link">
										{'Skellefteå museum'}
									</Link>
								</Subtitle>
							</Grid>
							
						</Grid>

						<Grid container justifyContent="center">
							<Grid item xs={12} lg={12}>
								<Stacke alt="Bild från riksarkivet" />
							</Grid> 
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Wrapper>
	);
}

export default History;

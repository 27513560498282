import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import Divider from "../../components/Divider";
import Wrapper from "../../components/Wrapper";

const Typography = styled(MuiTypography)(spacing);



const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  text-align: left;
`;

const ArrowForward = styled(ArrowForwardIcon)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;


function AboutUs() {
	return (
		<Wrapper useBg={true}>
			<Container>
				<Grid container alignItems="center" justifyContent="center" spacing={4}>
					<Grid item xs={12} sm={9} md={8} lg={8}>
						
						<Divider index={2} >
							{"Om oss"}
						</Divider>
					
						<Grid container justifyContent="center">
							<Grid item xs={12} lg={10}>
								<Subtitle color="textSecondary">
								{"Café Kajutan är en mötesplats strax utanför Skellefteå stadskärna för både stora och små sällskap. Här är alla välkomna att ses och mötas, äta och dricka, från förmiddag till sen kväll. I vårt café erbjuder vi bland annat våra kända räkmackor samt kyckling, salami & brie, ost & skinka. Vi har även lättare mat så som pizza, bakat potatis samt plättar för dom små."}
								<br />
									{"Vi har även en camping just bredvid området som du hittar mer info om här"}
								</Subtitle>
							</Grid>
						</Grid>

						<Box my={6} >
							<Button
							href="https://campkajutan.se"
							variant="contained"
							color="secondary"
							size="large"
							>
							Camp Kajutan
							<ArrowForward />
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Wrapper>
	);
}

export default AboutUs;

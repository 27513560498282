import * as React from "react";
import styled from "styled-components/macro";

import {
	Box,
} from "@mui/material";


const BgBox = styled(Box)(({ theme }) => ({
	background: "left top no-repeat",
    backgroundColor:  theme.palette.secondary.main,
    backgroundImage: 'url("static/img/backgrounds/compass.svg")',
    width: "100%",
    height: "auto",
	[theme.breakpoints.up("sm")]: {
	}
}));


const TopBackground = ({children}) => (
    <BgBox>
        {children}
    </BgBox>
);

export default TopBackground;

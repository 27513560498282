import React from "react";

import TopBar from "./TopBar";
import AppBar from "./AppBar";
import TopBackground from "./TopBackground";

import AboutUs from "./AboutUs";
import History from "./History";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Openings from "./Openings";
import Event from "./Event";
import Menu from "./Menu";



function Presentation() {
  return (
    <React.Fragment>
      <TopBackground>
        <TopBar />
        <AppBar />
      </TopBackground>
      <Openings />
      <Menu />
      <AboutUs />
      <History />
      <ContactUs />

      <Footer />
      

    </React.Fragment>
  );
}

export default Presentation;

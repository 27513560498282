import * as React from "react";
import styled from "styled-components/macro";

import {
	Box,
	Typography,
} from "@mui/material";

import { ReactComponent as Logo } from "../../../vendor/logo.svg";





const BrandIcon = styled(Logo)(({ theme }) => ({
	marginRight: theme.spacing(2),
	marginTop: "-2px",
	color: theme.header.brand.color,
	fill: theme.header.brand.color,
	maxWidth: "300px",
	width: "50%",
	height: "auto",
	verticalAlign: "middle",
	display: "inline",
	[theme.breakpoints.up("sm")]: {
		width: "100%",
	}
}));

const HeaderText = styled(Typography)(({ theme }) => ({
	color: theme.header.color,
	fontSize: "1.5rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "2.5rem"
	}
}));


const TopBarComponent = () => (
	<React.Fragment>
		<Box sx={{
			display: 'flex',
			justifyContent: 'center'
		}} mt={10} mb={2}>
			<BrandIcon />
		</Box>

		<Box sx={{
				display: 'flex',
				justifyContent: 'center'
		
			}}
			mt={7}
			mb={2}>
			<HeaderText variant="h1" >
				{"Sommarcafé 25 Maj - 25 Augusti"}
			</HeaderText>
		</Box>
	</React.Fragment>
);

export default TopBarComponent;

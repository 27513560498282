import React from "react";
import styled from "styled-components/macro";
import { useTheme } from '@mui/styles';

const WrapperDiv = styled.div`
	padding-top: 4.0rem;
	padding-bottom: 4.0rem;
	position: relative;
	text-align: center;
	overflow: hidden;
`;


function Wrapper({useBg, children}){
    const theme = useTheme();

    return <WrapperDiv  style={{backgroundColor: (useBg ? theme.palette.background.paper : 'inherit')}}>
        {children}
    </WrapperDiv>
}
export default Wrapper;
